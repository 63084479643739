/* eslint-disable camelcase */

import location_icon from "../assets/Icons/LightPurple/location_icon_large.svg";
import location_icon_white from "../assets/Icons/BoldWhite/location_icon_white.svg";

import accessible_facilities_icon from "../assets/Icons/LightPurple/light_purple_accessible-toilet.svg";
import accessible_facilities_icon_white from "../assets/Icons/BoldWhite/bold_white_accessible-toilet.svg";

import gral_teaching_room_icon from "../assets/Icons/LightPurple/light_purple_teaching-room.svg";
import gral_teaching_room_icon_white from "../assets/Icons/BoldWhite/bold_white_teaching-room.svg";

import food_beverage_icon from "../assets/Icons/LightPurple/light_purple_food-and-beverage.svg";
import food_beverage_icon_white from "../assets/Icons/BoldWhite/bold_white_food-and-beverage.svg";

import all_gender_toilets_icon from "../assets/Icons/LightPurple/light_purple_gender-neutral-toilet.svg";
import all_gender_toilets_icon_white from "../assets/Icons/BoldWhite/bold_white_gender-neutral-toilet.svg";

import maletoilets_icon from "../assets/Icons/LightPurple/light_purple_male-toilet.svg";
import maletoilets_icon_white from "../assets/Icons/BoldWhite/bold_white_male-toilet.svg";

import femaletoilets_icon from "../assets/Icons/LightPurple/light_purple_female-toilet.svg";
import femaletoilets_icon_white from "../assets/Icons/BoldWhite/bold_white_female-toilet.svg";

import lecture_theatres_icon from "../assets/Icons/LightPurple/light_purple_lecture-theatre.svg";
import lecture_theatres_icon_white from "../assets/Icons/BoldWhite/bold_white_lecture-theatre.svg";

import computer_lab_icon from "../assets/Icons/LightPurple/light_purple_computer-lab.svg";
import computer_lab_icon_white from "../assets/Icons/BoldWhite/bold_white_computer-lab.svg";

import computer_lab_teaching_icon from "../assets/Icons/LightPurple/light_purple_computer-lab-teaching.svg";
import computer_lab_teaching_icon_white from "../assets/Icons/BoldWhite/bold_white_computer-lab-teaching.svg";

import askus_icon from "../assets/Icons/LightPurple/light_purple_askus-booth.svg";
import askus_icon_white from "../assets/Icons/BoldWhite/bold_white_askus-booth.svg";

import office_icon from "../assets/Icons/LightPurple/light_purple_office.svg";
import office_icon_white from "../assets/Icons/BoldWhite/bold_white_office.svg";

import study_space_icon from "../assets/Icons/LightPurple/light_purple_study-spaces.svg";
import study_space_icon_white from "../assets/Icons/BoldWhite/bold_white_study-spaces.svg";

import water_refill_icon from "../assets/Icons/LightPurple/light_purple_water-refill-station.svg";
import water_refill_icon_white from "../assets/Icons/BoldWhite/bold_white_water-refill-station.svg";

import retail_icon from "../assets/Icons/LightPurple/light_purple_retail.svg";
import retail_icon_white from "../assets/Icons/BoldWhite/bold_white_retail.svg";

import retail_specialty_icon from "../assets/Icons/LightPurple/retail_specialtyicon.svg";
import retail_specialty_icon_white from "../assets/Icons/BoldWhite/retail_specialtyicon_white.svg";

import dry_lab_icon from "../assets/Icons/LightPurple/light_purple_dry-lab.svg";
import dry_lab_icon_white from "../assets/Icons/BoldWhite/bold_white_dry-lab.svg";

import microwave_icon from "../assets/Icons/LightPurple/light_purple_microwave.svg";
import microwave_icon_white from "../assets/Icons/BoldWhite/bold_white_microwave.svg";

import hand_sanitizer_icon from "../assets/Icons/LightPurple/light_purple_hand-sanitiser.svg";
import hand_sanitizer_icon_white from "../assets/Icons/BoldWhite/bold_white_hand-sanitiser.svg";

import bike_repair_icon from "../assets/Icons/LightPurple/light_purple_bike-repair-station.svg";
import bike_repair_icon_white from "../assets/Icons/BoldWhite/bold_white_bike-repair-station.svg";

import bike_storage_icon from "../assets/Icons/LightPurple/light_purple_bike-storage-room.svg";
import bike_storage_icon_white from "../assets/Icons/BoldWhite/bold_white_bike-storage-room.svg";

import bike_box_icon from "../assets/Icons/LightPurple/light_purple_bike-box.svg";
import bike_box_icon_white from "../assets/Icons/BoldWhite/bold_white_bike-box.svg";

import bike_rack_icon from "../assets/Icons/LightPurple/light_purple_bike-rack.svg";
import bike_rack_icon_white from "../assets/Icons/BoldWhite/bold_white_bike-rack.svg";

import emergency_point_icon from "../assets/Icons/LightPurple/light_purple_emergency-distress-beacon.svg";
import emergency_point_icon_white from "../assets/Icons/BoldWhite/bold_white_emergency-distress-beacon.svg";

import library_icon from "../assets/Icons/LightPurple/light_purple_library.svg";
import library_icon_white from "../assets/Icons/BoldWhite/bold_white_library.svg";

import museum_icon from "../assets/Icons/LightPurple/light_purple_museum.svg";
import museum_icon_white from "../assets/Icons/BoldWhite/bold_white_museum.svg";

import safety_bus_stop_icon from "../assets/Icons/LightPurple/light_purple_safety-bus-stop.svg";
import safety_bus_stop_icon_white from "../assets/Icons/BoldWhite/bold_white_safety-bus-stop.svg";

import sport_and_fitness_icon from "../assets/Icons/LightPurple/light_purple_sport-and-fitness.svg";
import sport_and_fitness_icon_white from "../assets/Icons/BoldWhite/bold_white_sport-and-fitness.svg";

import car_park_icon from "../assets/Icons/LightPurple/light_purple_car-parking.svg";
import car_park_icon_white from "../assets/Icons/BoldWhite/bold_white_car-parking.svg";

import parenting_facility_icon from "../assets/Icons/LightPurple/light_purple_parenting-facility.svg";
import parenting_facility_icon_white from "../assets/Icons/BoldWhite/bold_white_parenting-facility.svg";

import bank_atm_icon from "../assets/Icons/LightPurple/light_purple_banks-and-atms.svg";
import bank_atm_icon_white from "../assets/Icons/BoldWhite/bold_white_banks-and-atms.svg";

import landmark_icon from "../assets/Icons/LightPurple/light_purple_landmark.svg";
import landmark_icon_white from "../assets/Icons/BoldWhite/bold_white_landmark.svg";

import citycycle_icon from "../assets/Icons/LightPurple/light_purple_citycycle.svg";
import citycycle_icon_white from "../assets/Icons/BoldWhite/bold_white_citycycle.svg";

import motorcycle_parking_icon from "../assets/Icons/LightPurple/light_purple_motorcycle-parking.svg";
import motorcycle_parking_icon_white from "../assets/Icons/BoldWhite/bold_white_motorcycle-parking.svg";

import printer_icon from "../assets/Icons/LightPurple/light_purple_printer.svg";
import printer_icon_white from "../assets/Icons/BoldWhite/bold_white_printer.svg";

import shower_icon from "../assets/Icons/LightPurple/light_purple_shower.svg";
import shower_icon_white from "../assets/Icons/BoldWhite/bold_white_shower.svg";

import transport_icon from "../assets/Icons/LightPurple/light_purple_transport.svg";
import transport_icon_white from "../assets/Icons/BoldWhite/bold_white_transport.svg";

import payphone_icon from "../assets/Icons/LightPurple/light_purple_payphone.svg";
import payphone_icon_white from "../assets/Icons/BoldWhite/bold_white_payphone.svg";

import bench_exercise_icon from "../assets/Icons/LightPurple/light_purple_bench_exercise.svg";
import bench_exercise_icon_white from "../assets/Icons/BoldWhite/bold_white_bench_exercise.svg";

import esports_icon from "../assets/Icons/LightPurple/light_purple_esports.svg";
import esports_icon_white from "../assets/Icons/BoldWhite/bold_white_esports.svg";

import faith_and_religion_icon from "../assets/Icons/LightPurple/light_purple_faith-and-religion.svg";
import faith_and_religion_icon_white from "../assets/Icons/BoldWhite/bold_white_faith-and-religion.svg";

import function_venue_icon from "../assets/Icons/LightPurple/light_purple_function-venue.svg";
import function_venue_icon_white from "../assets/Icons/BoldWhite/bold_white_function-venue.svg";

import defibrillator_icon from "../assets/Icons/LightPurple/light_purple_defibrillator-aed.svg";
import defibrillator_icon_white from "../assets/Icons/BoldWhite/bold_white_defibrillator-aed.svg";

const teaching_room_icon = gral_teaching_room_icon;
const teaching_room_icon_white = gral_teaching_room_icon_white;

import info_point_icon from "../assets/Icons/LightPurple/light_purple_info-point.svg";
import info_point_icon_white from "../assets/Icons/BoldWhite/bold_white_info-point.svg";

import kitchenette_icon from "../assets/Icons/LightPurple/light_purple_kitchenette.svg";
import kitchenette_icon_white from "../assets/Icons/BoldWhite/bold_white_kitchenette.svg";

import book_return_icon from "../assets/Icons/LightPurple/light_purple_book-return.svg";
import book_return_icon_white from "../assets/Icons/BoldWhite/bold_white_book-return.svg";

import running_exercise_icon from "../assets/Icons/LightPurple/light_purple_running_exercise.svg";
import running_exercise_icon_white from "../assets/Icons/BoldWhite/bold_white_running_exercise.svg";

import schools_and_faculties_icon from "../assets/Icons/LightPurple/light_purple_schools-and-faculties.svg";
import schools_and_faculties_icon_white from "../assets/Icons/BoldWhite/bold_white_schools-and-faculties.svg";

import security_office_icon from "../assets/Icons/LightPurple/light_purple_security-office.svg";
import security_office_icon_white from "../assets/Icons/BoldWhite/bold_white_security-office.svg";

import stretching_exercise_icon from "../assets/Icons/LightPurple/light_purple_stretching_exercise.svg";
import stretching_exercise_icon_white from "../assets/Icons/BoldWhite/bold_white_stretching_exercise.svg";

import ambulent_toilet from "../assets/Icons/LightPurple/light_purple_ambulant-toilet.svg";
import ambulent_toilet_white from "../assets/Icons/BoldWhite/bold_white_ambulant-toilet.svg";

import wet_lab_icon from "../assets/Icons/LightPurple/light_purple_wet-lab.svg";
import wet_lab_icon_white from "../assets/Icons/BoldWhite/bold_white_wet-lab.svg";

export {
  location_icon,
  location_icon_white,
  accessible_facilities_icon,
  accessible_facilities_icon_white,
  gral_teaching_room_icon,
  gral_teaching_room_icon_white,
  food_beverage_icon,
  food_beverage_icon_white,
  all_gender_toilets_icon,
  all_gender_toilets_icon_white,
  maletoilets_icon,
  maletoilets_icon_white,
  femaletoilets_icon,
  femaletoilets_icon_white,
  lecture_theatres_icon,
  lecture_theatres_icon_white,
  computer_lab_icon,
  computer_lab_icon_white,
  computer_lab_teaching_icon,
  computer_lab_teaching_icon_white,
  askus_icon,
  askus_icon_white,
  office_icon,
  office_icon_white,
  study_space_icon,
  study_space_icon_white,
  water_refill_icon,
  water_refill_icon_white,
  retail_icon,
  retail_icon_white,
  retail_specialty_icon,
  retail_specialty_icon_white,
  dry_lab_icon,
  dry_lab_icon_white,
  microwave_icon,
  microwave_icon_white,
  hand_sanitizer_icon,
  hand_sanitizer_icon_white,
  bike_repair_icon,
  bike_repair_icon_white,
  bike_storage_icon,
  bike_storage_icon_white,
  bike_box_icon,
  bike_box_icon_white,
  bike_rack_icon,
  bike_rack_icon_white,
  emergency_point_icon,
  emergency_point_icon_white,
  library_icon,
  library_icon_white,
  museum_icon,
  museum_icon_white,
  safety_bus_stop_icon,
  safety_bus_stop_icon_white,
  sport_and_fitness_icon,
  sport_and_fitness_icon_white,
  car_park_icon,
  car_park_icon_white,
  parenting_facility_icon,
  parenting_facility_icon_white,
  bank_atm_icon,
  bank_atm_icon_white,
  landmark_icon,
  landmark_icon_white,
  citycycle_icon,
  citycycle_icon_white,
  motorcycle_parking_icon,
  motorcycle_parking_icon_white,
  printer_icon,
  printer_icon_white,
  shower_icon,
  shower_icon_white,
  transport_icon,
  transport_icon_white,
  payphone_icon,
  payphone_icon_white,
  bench_exercise_icon,
  bench_exercise_icon_white,
  esports_icon,
  esports_icon_white,
  faith_and_religion_icon,
  faith_and_religion_icon_white,
  function_venue_icon,
  function_venue_icon_white,
  teaching_room_icon,
  teaching_room_icon_white,
  info_point_icon,
  info_point_icon_white,
  kitchenette_icon,
  kitchenette_icon_white,
  book_return_icon,
  book_return_icon_white,
  running_exercise_icon,
  running_exercise_icon_white,
  schools_and_faculties_icon,
  schools_and_faculties_icon_white,
  security_office_icon,
  security_office_icon_white,
  stretching_exercise_icon,
  stretching_exercise_icon_white,
  ambulent_toilet,
  ambulent_toilet_white,
  wet_lab_icon,
  wet_lab_icon_white,
  defibrillator_icon,
  defibrillator_icon_white
};
